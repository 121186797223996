

import { ReactComponent as Svg } from "../images/spinner.svg";

import styles from "./Spinner.module.css";

const Spinner = ({
  size = 44,
  color = "currentColor",
  className = "",
  ...rest
}) => {
  return (
    <Svg
      className={`${styles.spinner} ${className}`}
      width={size}
      style={{ color }}
      {...rest}
    />
  );
};
export default Spinner;
