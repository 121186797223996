import { useState, useEffect } from "react";
import ErrorBoundary from "@honeybadger-io/react";
import { Capacitor } from "@capacitor/core";

import { ModalHeader, modals } from "../views/Modals.js";
import honeybadger from "../honeybadger.js";
import Button from "../components/Button.js";
import useParams from "../utilities/useParams.js";
import i18n from "../localization.js";

import errorSrc from "../images/error.svg";
import offlineSrc from "../images/offline.svg";

const OurErrorBoundary = ({ children, inline = true }) => {
  return (
    <ErrorBoundary
      honeybadger={honeybadger}
      ErrorComponent={inline ? InlineErrorViewWrapper : ErrorViewWrapper}
    >
      <>{children}</>
    </ErrorBoundary>
  );
};

export default OurErrorBoundary;

const ErrorViewWrapper = (props) => <ErrorView {...props} inline={false} />;
const InlineErrorViewWrapper = (props) => (
  <ErrorView {...props} inline={true} />
);

export const ErrorView = ({
  error = null,
  info = null,
  errorOccurred,
  hideCloseButton = false,
  inline,
}) => {
  const query = useParams();
  const isModalView = Object.keys(modals).some((modal) => query.has(modal));

  if (error) {
    console.log(error);
  }
  if (info) {
    console.log(info);
  }

  const errorMessages = {
    general: {
      title: i18n.t("ErrorView.generalErrorTitle"),
      message: i18n.t("ErrorView.generalErrorMessage"),
      image: errorSrc,
      imageWidth: 110,
    },
    outOfDateWeb: {
      title: i18n.t("ErrorView.applicationUpdatedTitle"),
      message: i18n.t("ErrorView.applicationUpdatedMessage"),
      image: errorSrc,
      imageWidth: 110,
    },
    outOfDateNative: {
      title: i18n.t("ErrorView.nativeOutOfDateTitle"),
      message: i18n.t("ErrorView.nativeOutOfDateMessage"),
      image: errorSrc,
      imageWidth: 110,
    },
    offline: {
      title: i18n.t("ErrorView.connectionOfflineTitle"),
      message: i18n.t("ErrorView.connectionOfflineMessage"),
      image: offlineSrc,
      imageWidth: 136,
    },
    gone: {
      title: i18n.t("ErrorView.goneTitle"),
      message: i18n.t("ErrorView.goneMessage"),
      image: errorSrc,
      imageWidth: 110,
    },
  };

  let errorMessage = errorMessages.general;
  let shouldShowRefreshButton = true;

  if (error?.name === "ChunkLoadError") {
    const isProbablyDueToBeingOffline = !navigator.onLine;
    errorMessage = isProbablyDueToBeingOffline
      ? errorMessages.offline
      : errorMessages.outOfDateWeb;
  } else if (error?.status === 410) {
    errorMessage = errorMessages.gone;
  } else if (error?.status === 501 && error?.json?.name === "OldVersionError") {
    errorMessage =
      errorMessages[Capacitor.isNative ? "outOfDateNative" : "outOfDateWeb"];
    if (Capacitor.isNative) shouldShowRefreshButton = false;
  }

  return inline ? (
    <p style={{ color: "var(--red)" }}>
      <strong>{errorMessage.title}</strong>
    </p>
  ) : (
    <div className="dialog" style={{ padding: "var(--gap)" }}>
      {isModalView && !hideCloseButton && <ModalHeader showClose />}
      <img
        src={errorMessage.image}
        alt=""
        width={errorMessage.imageWidth}
        style={{ justifySelf: "center" }}
      />

      <div style={{ display: "grid", gridGap: "4px" }}>
        <h1 className="title">{errorMessage.title}</h1>
        <p className="large">{errorMessage.message}</p>
      </div>

      {shouldShowRefreshButton && <RefreshButton />}
    </div>
  );
};

export const RefreshButton = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const checkOnline = () => {
      if (navigator.onLine !== isOnline) {
        setIsOnline(navigator.onLine);
      }
    };

    window.addEventListener("online", checkOnline);
    window.addEventListener("offline", checkOnline);
    window.addEventListener("visibilitychange", checkOnline);

    return () => {
      window.removeEventListener("online", checkOnline);
      window.removeEventListener("offline", checkOnline);
      window.removeEventListener("visibilitychange", checkOnline);
    };
  }, [isOnline]);

  return (
    <Button
      variant="primary"
      onClick={() => window.location.reload()}
      disabled={!isOnline}
    >
      {i18n.t("ErrorView.refreshApplication")}
      {!isOnline && (
        <div className="tiny">{i18n.t("ErrorView.connectionOffline")}</div>
      )}
    </Button>
  );
};
