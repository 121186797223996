import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUpdateStatus } from "../queries/queries";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";

export async function checkUpdateStatus(locale, history) {
  const isNative = Capacitor.getPlatform() !== "web";
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  const testVersion = urlParams.get("version");
  if (isNative) {
    const appInfo = await App.getInfo();
    const updateStatus = await getUpdateStatus(locale, appInfo.version);
    if (updateStatus.status !== 1) {
      history.push({ search: "?update-prompt", state: updateStatus });
    }
  }
  if (testVersion) {
    const updateStatus = await getUpdateStatus(locale, testVersion);
    console.log("updateStatus", updateStatus, testVersion);
    if (updateStatus.status !== 1) {
      history.push({ search: "?update-prompt", state: updateStatus });
    }
  }
}
