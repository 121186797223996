import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useHighlightRipple } from "../components/HighlightRipple.js";
import Button from "../components/Button.js";
import ConnectionHeader from "../components/ConnectionHeader.js";
import { ReactComponent as QRScan } from "../images/icons/QRScan.svg";
import valoMotionLogo from "../images/logos/valo-motion-icon.svg";

import styles from "./Header.module.css";

const Header = ({ connection }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [rippleElement, triggerRipple] = useHighlightRipple();

  return (
    <header
      className={styles.container}
      onTouchEnd={triggerRipple}
      onClick={triggerRipple}
    >
      <div className={styles.header}>
        <h1>
          <Link to="/">
            <img src={valoMotionLogo} alt="Valo Motion" />
          </Link>

          {process.env.REACT_APP_API_ROOT ===
            "https://cloud.vm-staging.com" && (
            <span
              style={{
                color: "var(--orange)",
                position: "absolute",
                left: "33px",
                top: 0,
                bottom: 0,
                margin: "auto",
                fontSize: "10px",
                lineHeight: "1em",
                height: "1em",
              }}
            >
              STAGING
            </span>
          )}
        </h1>

        <Button as="Link" to={pathname + "?scan"} className={styles.button}>
          <QRScan className="icon" style={{ "--size": "24px" }} />
          &nbsp;
          {t("Header.Scan")}
        </Button>
      </div>

      <ConnectionHeader connection={connection} />

      {rippleElement}
    </header>
  );
};
export default Header;
