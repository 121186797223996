import { Preferences } from "@capacitor/preferences";

export const clearStorageCode = async () => {
  await Preferences.set({
    key: "code",
    value: JSON.stringify({
      code: "null",
    }),
  });
};

export const getStorageCode = async () => {
  const storageCode = await Preferences.get({ key: "code" });
  let value = JSON.parse(storageCode.value);
  return value?.code;
};

export const clearContestId = async () => {
  await Preferences.set({
    key: "contestId",
    value: JSON.stringify({
      contestId: "null",
    }),
  });
};

export const setContestId = async (contestId) => {
  await Preferences.set({
    key: "contestId",
    value: JSON.stringify({
      contestId: contestId,
    }),
  });
};

export const getContestId = async () => {
  const contestId = await Preferences.get({ key: "contestId" });
  let value = JSON.parse(contestId.value);
  return value?.contestId;
};

export const saveStorageConnectionCode = async (storageConnectionCode) => {
  await Preferences.set({
    key: "code",
    value: JSON.stringify({
      code: storageConnectionCode,
    }),
  });
};

export const getStorageVideos = async () => {
  const storageVideos = await Preferences.get({ key: "storageVideos" });
  let value = JSON.parse(storageVideos.value);
  return value?.storageVideos;
};

export const getStorageVideosJSON = async () => {
  const storageVideos = await Preferences.get({ key: "storageVideos" });

  return storageVideos;
};

export const setStorageVideos = async (videoArray) => {
  await Preferences.set({
    key: "storageVideos",
    value: JSON.stringify({
      storageVideos: videoArray,
    }),
  });
};

export const clearStorageVideos = async () => {
  await Preferences.set({
    key: "storageVideos",
    value: JSON.stringify({
      storageVideos: "null",
    }),
  });
};

export const getWelcomeStatus = async () => {
  const hasBeenWelcomed = await Preferences.get({ key: "userWelcomed" });
  let value = JSON.parse(hasBeenWelcomed.value);
  return value?.userWelcomed;
};

export const setWelcomeStatus = async (status) => {
  await Preferences.set({
    key: "userWelcomed",
    value: JSON.stringify({
      userWelcomed: status,
    }),
  });
};
