import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import styles from "./Toast.module.css";
import "react-toastify/dist/ReactToastify.css";
import useParams from "../utilities/useParams.js";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";

const Toast = ({ autoClose = 2000 }) => {
  const params = useParams();
  const hasMessage = params.has("msg");
  const accountConfirmation = params.has("account_confirmation_success");

  const { t } = useTranslation();

  useEffect(() => {
    if (!Capacitor.isNative) {
      if (hasMessage && !accountConfirmation) {
        let text = params.get("msg");
        toast(<p style={{ color: "#022548" }}>{t(text)}</p>);
      }
    }

    if (accountConfirmation) {
      toast(<p style={{ color: "#022548" }}>{t("Msg.emailConfirmed")}</p>);
    }
  }, [hasMessage]);

  return (
    <ToastContainer
      position="top-center"
      autoClose={autoClose}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      closeButton={false}
      draggable
      toastClassName={styles.toast}
      pauseOnHover
    />
  );
};

export default Toast;
