import { useRef, useCallback, forwardRef } from "react";

import styles from "./HighlightRipple.module.css";

export const HighlightRipple = forwardRef((props, ref) => {
  return (
    <div className={styles.container} aria-hidden="true">
      <div className={styles.ripple} ref={ref} />
    </div>
  );
});
export default HighlightRipple;

const isSupported = "animate" in document.body;
const nothing = () => {};
const options = { duration: 262, easing: "ease-out", fill: "both" };
let ignoreClicks = false;

export const useHighlightRipple = () => {
  const ref = useRef();

  const triggerRipple = useCallback((event) => {
    const isTouch = event.type === "touchend" || event.type === "touchstart";

    if (isTouch) {
      ignoreClicks = true;
    }

    if (ignoreClicks && !isTouch) {
      return;
    }

    const ripple = ref.current;
    const { left, top } = ripple.parentElement.getBoundingClientRect();

    const eventX = isTouch ? event.changedTouches[0].pageX : event.pageX;
    const eventY = isTouch ? event.changedTouches[0].pageY : event.pageY;

    const x = eventX - left - window.pageXOffset;
    const y = eventY - top - window.pageYOffset;

    const keyframes = [
      {
        transform: `translate(${x}px, ${y}px) translate(-50%, -50%) scale(0)`,
        opacity: 0,
      },
      {
        opacity: 1,
      },
      {
        opacity: 0,
        transform: `translate(${x}px, ${y}px) translate(-50%, -50%) scale(1)`,
      },
    ];

    ripple.animate(keyframes, options);
  }, []);

  return [<HighlightRipple ref={ref} />, isSupported ? triggerRipple : nothing];
};
