import MetaTags from "react-meta-tags";
import socialImage from "../social-image-duplicate-for-metatags.jpg";
import useParams from "../utilities/useParams.js";
import { modals } from "../views/Modals.js";

const PageMetaTags = ({ title, description, video, context, isModalView }) => {
  const player = video?.app_user_name ? video?.app_user_name : "player";
  const videoByText = "A video by " + player;

  return (
    <>
      <MetaTags>
        {!isModalView ? (
          <>
            <meta name="description" id={context} content={description} />
            {/*feshbuk*/}
            <meta
              property="og:description"
              id={context}
              content={description}
            />
            <meta property="og:type" id={context} content="website" />
            <meta property="og:title" id={context} content={title} />
            <meta property="og:image" id={context} content={socialImage} />
            {/* tweetor */}

            <meta
              name="twitter:card"
              id={context}
              content="summary_large_image"
            />

            <meta name="twitter:site" id={context} content="@valomotion" />

            <meta name="twitter:title" id={context} content={title} />
            <meta
              name="twitter:description"
              id={context}
              content={description}
            />
            <meta name="twitter:image" id={context} content={socialImage} />

            {/*googel*/}

            <meta itemProp="name" id={context} content={title} />
            <meta itemProp="description" id={context} content={description} />
            <meta itemProp="image" id={context} content={socialImage} />
          </>
        ) : (
          <>
            <title>{description}</title>

            {/*feshbuk*/}
            <meta name="description" id={context} content={videoByText} />
            <meta property="og:video" id={context} content="video.other" />
            <meta
              property="og:video:url"
              id={context}
              content={video?.direct_url}
            />
            <meta property="og:url" id={context} content={video?.direct_url} />
            <meta property="og:video:type" id={context} content="video/mp4" />
            <meta name="og:video:width" id={context} content="200" />
            <meta name="og:video:height" id={context} content="200" />
            <meta
              property="og:video:secure_url"
              id={context}
              content={video?.direct_url}
            />
            <meta
              property="og:image"
              id={context}
              content={video?.direct_thumb_url}
            />

            {/* tweetor */}
            <meta name="twitter:card" id={context} content="player" />
            <meta name="twitter:title" id={context} content={description} />
            <meta
              name="twitter:description"
              id={context}
              content={videoByText}
            />
            <meta name="twitter:site" id={context} content="@valomotion" />

            <meta
              name="twitter:player"
              id={context}
              content={video?.direct_url}
            />
            <meta name="twitter:player:width" id={context} content="200" />
            <meta name="twitter:player:height" id={context} content="200" />
            <meta
              name="twitter:image"
              id={context}
              content={video?.direct_thumb_url}
            />

            {/*googel*/}
            <meta itemProp="name" id={context} content={title} />
            <meta itemProp="description" id={context} content={description} />
            <meta itemProp="image" id={context} content={socialImage} />
          </>
        )}
      </MetaTags>
    </>
  );
};

export default PageMetaTags;
