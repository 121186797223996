const languages = [
  ["en-US", "English"],
  ["zh-CN", "Chinese (simplified)"],
  ["nl-NL", "Dutch"],
  ["fi-FI", "Finnish"],
  ["fr-FR", "French"],
  ["de-DE", "German"],
  ["ja-JP", "Japanese"],
  ["es-ES", "Spanish"],
];

const languageCodes = languages.map((language) => language[0]);

module.exports = {
  languages,
  languageCodes,
};
