import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enUS from './locales/en-US.json';
import deDE from './locales/de-DE.json';
import esES from './locales/es-ES.json';
import fiFI from './locales/fi-FI.json';
import frFR from './locales/fr-FR.json';
import jaJP from './locales/ja-JP.json';
import nlNL from './locales/nl-NL.json';
import zhCN from './locales/zh-CN.json';

import { languageCodes } from "./supportedLanguages.js";

const savedLanguage = localStorage.getItem("language");
const savedLanguageIsValid = languageCodes.includes(savedLanguage);

i18n
  .use(initReactI18next)
  .init(
    {
      resources: {
        'en-US': { translation: enUS },
        'de-DE': { translation: deDE },
        'es-ES': { translation: esES },
        'fi-FI': { translation: fiFI },
        'fr-FR': { translation: frFR },
        'ja-JP': { translation: jaJP },
        'nl-NL': { translation: nlNL },
        'zh-CN': { translation: zhCN },
      },
      supportedLngs: languageCodes,
      fallbackLng: "en-US",
      lng:
        (savedLanguageIsValid && savedLanguage) ||
        navigator.language ||
        navigator.userLanguage,

      interpolation: { escapeValue: false },
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: [
          "br",
          "strong",
          "b",
          "i",
          "em",
          "mark",
          "code",
        ],
      },
      // debug: process.env.NODE_ENV === "development",
    },
    (err, t) => {
      if (err) return console.log("something went wrong loading", err);
    }
  );

i18n.on("languageChanged", (lng) =>
  document.documentElement.setAttribute("lang", lng)
);

export default i18n;
