import Spinner from "../components/Spinner.js";

import styles from "./LoadingScreen.module.css";

const LoadingScreen = ({
  title,
  message,
  className = "",
  spinnerColor = "var(--blue)",
}) => {
  return (
    <div className={`dialog ${styles.container} ${className}`}>
      <Spinner color={spinnerColor} size={72} />
      <div className={styles.text}>
        {title && <h1 className="title">{title}</h1>}
        {message && <p className="large">{message}</p>}
      </div>
    </div>
  );
};
export default LoadingScreen;
