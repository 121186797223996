import { QueryCache, ReactQueryCacheProvider } from "react-query";

export const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      suspense: true,
      // With this, navigation between views or re-tapping a navigation item
      // will always cause a refetch, which is what we want.
      // This could also cause a bit too many refetches in some cases,
      // so for optimization this could be changed to `true` and augmented with
      // lots of manual `invalidateQueries` calls.
      refetchOnMount: "always",
    },
    // mutations: {
    //   suspense: true,
    // },
  },
});

function QueryProvider(props) {
  return <ReactQueryCacheProvider queryCache={queryCache} {...props} />;
}

export { QueryProvider };
