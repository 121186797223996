import React, {
  Fragment,
  lazy,
  Suspense,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import { MobileAccessibility } from "@ionic-native/mobile-accessibility";
import { Route, useHistory, useLocation, Switch } from "react-router-dom";
import { useMutation, useQueryCache } from "react-query";
import { ReactTitle } from "react-meta-tags";
import { PushNotifications } from "@capacitor/push-notifications";
import { Keyboard } from "@capacitor/keyboard";
import { Browser } from "@capacitor/browser";
import { App as CapApp } from "@capacitor/app";
import useParams from "./utilities/useParams.js";
import { useConnection } from "./utilities/codeHooks.js";
import { getStorageVideosJSON } from "./utilities/storageFunctions";

import { getConnections } from "./queries/queries";
import { useUser } from "./contexts/AuthContext";
import Navigation from "./components/Navigation.js";
import Toast from "./components/Toast.js";

import Modals from "./views/Modals.js";
import Header from "./components/Header.js";
import PageMetaTags from "./components/PageMetaTags.js";
import LoadingScreen from "./components/LoadingScreen.js";
import ErrorBoundary from "./components/ErrorBoundary.js";
import ScrollToTopHandler from "./components/ScrollToTopHandler.js";
import { LocalNotifications } from "@capacitor/local-notifications";
import { updateDeviceToken } from "./queries/queries";
import {
  getWelcomeStatus,
  getStorageCode,
  clearStorageCode,
  getContestId,
  clearContestId,
  setWelcomeStatus,
} from "./utilities/storageFunctions";
import { useGuestVideoMergeMonitorHook } from "./utilities/codeHooks";
import { checkUpdateStatus } from "./utilities/checkUpdateStatus.js";

import styles from "./App.module.css";

const SmartBanner = lazy(() => import("./components/SmartBanner.js"));
const Home = lazy(() => import("./views/Home.js"));
const BrowseLocations = lazy(() => import("./views/BrowseLocations.js"));
const Leagues = lazy(() => import("./views/Leagues.js"));
const User = lazy(() => import("./views/User.js"));
const Notifications = lazy(() => import("./views/Notifications.js"));

const OnlyVisibleInDevelopment = lazy(() =>
  import("./views/OnlyVisibleInDevelopment.js")
);
const NotFound = lazy(() => import("./views/NotFound.js"));

const useInterval = (initialValue) => {
  const [seconds, setSeconds] = useState(initialValue);
  const { search } = useLocation();
  const modalOpen = search === "?scan";
  // const increment = Math.floor(seconds * 1.1);
  //TODO decrease seconds over time
  /*
  let incrementFunction = setTimeout(() => {
    setSeconds(increment);
  }, increment);

  let incrementor = useRef(incrementFunction);

  useEffect(() => {
    if (modalOpen) {
      clearTimeout(incrementFunction);
    }
  }, [seconds, modalOpen]);
*/
  useEffect(() => {
    if (modalOpen) {
      setSeconds(2000);
    }
    if (!modalOpen) {
      setTimeout(() => {
        setSeconds(5000);
      }, 6000);
    }
  }, [modalOpen]);

  return [seconds, setSeconds];
};

const useConnectionStatus = (props) => {
  const user = useUser();
  const [interval, setInterval] = useInterval(5000);
  const [num, setNum] = useState(0);
  const [connection, setConnction] = useState(false);

  useEffect(() => {
    if (user) {
      setTimeout(async () => {
        const deviceConnection = await getConnections();
        if (deviceConnection?.connected_at) {
          setConnction(deviceConnection);
        } else if (connection && !deviceConnection?.connected_at) {
          setConnction(null);
        }

        setNum(num + 1); //remounts component
      }, interval);
    }
  }, [num, user]);

  useEffect(() => {
    if (connection?.connected_at && interval !== 2000) {
      setInterval(2000);
    }
    if (!connection?.connected_at) {
      setInterval(5000);
    }
  }, [connection]);

  useEffect(() => {
    Browser.addListener("browserFinished", (event) => {
      console.log("event1");
      console.log(event);
    });

    Browser.addListener("browserPageLoaded", (event) => {
      console.log("event2");
      console.log(event);
    });
  }, []);

  return [connection];
  /*
  return (
    <>
      {!props.children.length
        ? React.cloneElement(props.children, { connection })
        : props.children.map((child, index) => (
            <Fragment key={index}>
              {React.cloneElement(child, { connection })}
            </Fragment>
          ))}
    </>
  );
  */
};

const App = () => {
  const { t } = useTranslation();
  const user = useUser();
  const { pathname } = useLocation();

  const {
    createConnection,
    // connectionError,
    // connectionSuccess,
    // clearError,
  } = useConnection();

  useGuestVideoMergeMonitorHook(user);
  const [connection] = useConnectionStatus(user);
  const history = useHistory();
  const { i18n } = useTranslation();
  const queryCache = useQueryCache();
  const [updateToken] = useMutation(updateDeviceToken, {
    onSuccess(data) {
      console.log(data.device_token);
    },
    onError(error) {
      alert("token update failed");
    },
  });
  // TODO: DEBUG: remove
  // debugger
  if (Capacitor.isNative) Keyboard.setAccessoryBarVisible({ isVisible: true });

  MobileAccessibility.usePreferredTextZoom(true);

  const initLocalNotifications = () => {
    LocalNotifications.requestPermissions().then((result) => {
      LocalNotifications.addListener(
        "localNotificationActionPerformed",
        (notification) => {
          console.log("pushhhh", notification);
          history.push(notification.notification.attachments.url);
        }
      );
    });
  };

  const initNotifications = useCallback(() => {
    if (Capacitor.isNative) {
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive) {
          PushNotifications.register();
        }
      });

      PushNotifications.addListener("registration", (token) => {
        updateToken(token.value);
      });

      PushNotifications.addListener("registrationError", (error) => {
        console.log("Error on registration: " + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        async (notification) => {
          queryCache.invalidateQueries("unseenNotificationsCount");

          let url = notification?.extra?.redirect
            ? notification.extra.redirect?.replace("mode=", "")
            : notification.data.redirect.replace("mode=", "");

          console.log("URLLLLLLL", url);
          await LocalNotifications.schedule({
            notifications: [
              {
                body: notification.body,
                title: notification.title,
                id: 1,
                schedule: { at: new Date(Date.now() + 100) },
                sound: null,
                attachments: {
                  id: "1",
                  url: url,
                },
              },
            ],
          });
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          let url = notification.notification.extra?.redirect
            ? notification.notification.extra.redirect?.replace("mode=", "")
            : notification.notification.data.redirect.replace("mode=", "");

          history.push(url);
        }
      );
    }
  }, [PushNotifications]);

  useEffect(() => {
    CapApp.addListener("appUrlOpen", (data: AppUrlOpen) => {
      console.log("data", data);
      const slug = data.url.split(".app").pop();
      const params = new URLSearchParams(data.url);
      const paramCode = params.get("c");

      Browser.close();
      if (!paramCode) {
        history.push(slug);
      } else {
        createConnection(paramCode);
      }
    });
  }, [initNotifications, history]);

  useEffect(() => {
    if (!user && Capacitor.isNative) {
      getWelcomeStatus().then((welcomed) => {
        if (welcomed !== "true") {
          history.push("/?welcome");
        }
      });
    } else if (user) {
      setWelcomeStatus("true");
    }
  }, [user, updateToken, history]);

  useEffect(() => {
    const checkForCode = async () => {
      await getStorageCode().then((storageCode) => {
        if (storageCode && storageCode !== "null") {
          clearStorageCode();
          history.push("/?scan&c=" + storageCode);
        }
      });
    };

    if (user) {
      checkForCode();
    }
  }, [user]);

  useEffect(() => {
    if (Capacitor.isNative) {
      initLocalNotifications();
    }
    if (user && Capacitor.isNative) {
      initNotifications();
    }
  }, [user]);

  useEffect(() => {
    const checkForRedirects = async () => {
      await getStorageCode().then((storageCode) => {
        if (storageCode && storageCode !== "null") {
          clearStorageCode();
          history.push("/?scan&c=" + storageCode + "&loading");
        } else {
          //  history.push("/");
        }
      });

      await getContestId().then((contestId) => {
        if (contestId && contestId !== "null") {
          clearContestId();
          history.push(`${pathname}?video-contest&contest_id=${contestId}`);
        } else {
          //  history.push("/");
        }
      });
    };

    if (user) {
      checkForRedirects();
    }
  }, [user]);

  useEffect(() => {
    checkUpdateStatus(i18n.language, history);
  }, []);

  return (
    <>
      <ScrollToTopHandler />

      {!Capacitor.isNative && (
        <Suspense fallback={null}>
          <SmartBanner />
        </Suspense>
      )}

      <div
        className={`${styles.container} ${Capacitor.isNative ? "is-native" : "is-web"
          }`}
      >
        <Suspense fallback={<div style={{ height: "var(--header-height)" }} />}>
          <>
            <Header connection={connection} />
            <Navigation connection={connection} />

            <Modals />
          </>
        </Suspense>

        <main className={styles.main}>
          <Suspense
            fallback={
              <div className="content">
                <LoadingScreen />
              </div>
            }
          >
            <ErrorBoundary inline={false}>
              <>
                <Switch>
                  <Route exact path="/">
                    <ErrorBoundary inline={false}>
                      <Home connection={connection} />
                      <ReactTitle title={t("Home.title")} />
                      <PageMetaTags
                        description={
                          "Share your sport experience, follow your scores & friends, see the best videos. Have the Active eSports league at your fingertips. Sign up!"
                        }
                        context="Home"
                      />
                    </ErrorBoundary>
                  </Route>
                  <Route path="/map">
                    <ErrorBoundary inline={false}>
                      <BrowseLocations />
                      <ReactTitle
                        title={t("LocationFinder.findALocationNearYou")}
                      />
                      <PageMetaTags
                        context="LeaguesApp"
                        description={
                          "The best gaming and Active eSports community for augmented climbing and trampoline. Become the global champion of ValoClimb & ValoJump. Sign up!"
                        }
                      />
                    </ErrorBoundary>
                  </Route>
                  <Route path="/leagues">
                    <ErrorBoundary inline={false}>
                      <Leagues />
                      <PageMetaTags
                        context="LeaguesApp"
                        description={
                          "The best gaming and Active eSports community for augmented climbing and trampoline. Become the global champion of ValoClimb & ValoJump. Sign up!"
                        }
                      />
                    </ErrorBoundary>
                  </Route>
                  <Route path="/user">
                    <ErrorBoundary inline={false}>
                      <User user={user} />
                    </ErrorBoundary>
                  </Route>
                  <Route path="/notifications">
                    <ErrorBoundary inline={false}>
                      <Notifications />
                      <ReactTitle
                        title={`Valo Motion | ${t("Notifications.title")}`}
                      />
                      <PageMetaTags context="Notifications" />
                    </ErrorBoundary>
                  </Route>

                  {process.env.NODE_ENV === "development" && (
                    <Route path="/.only-visible-in-development">
                      <OnlyVisibleInDevelopment />
                    </Route>
                  )}

                  <Route component={NotFound} />
                </Switch>
              </>
            </ErrorBoundary>
          </Suspense>
        </main>
      </div>

      <Toast />
    </>
  );
};

export default App;
