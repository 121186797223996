import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTopHandler = () => {
  const { pathname } = useLocation();
  const hasMountedRef = useRef(false);

  useEffect(() => {
    if (hasMountedRef.current) {
      window.scrollTo(window.pageXOffset, 0);
    }

    hasMountedRef.current = true;
  }, [pathname]);

  return null;
};
export default ScrollToTopHandler;
