import { useUser } from "../contexts/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import {
  clearStorageCode,
  getStorageCode,
  saveStorageConnectionCode,
  getStorageVideos,
  setStorageVideos,
  clearStorageVideos,
} from "./storageFunctions";
import { useQueryCache } from "react-query";
import useParams from "./useParams.js";

import {
  connectWithCode,
  attachStorageVideosToUserProfile,
  getMagicVideo,
} from "../queries/queries";

export const replaceStorageVideo = (replacementVideo, callback) => {
  getStorageVideos().then(async (storagedVideos) => {
    let updatedVideos = storagedVideos.map((storageVideo) => {
      if (storageVideo.videos.some((v) => v.id === replacementVideo.id)) {
        storageVideo.videos = storageVideo.videos.map((video) => {
          if (video.id === replacementVideo.id) {
            return replacementVideo;
          } else {
            return video;
          }
        });
        return storageVideo;
      } else {
        return storageVideo;
      }
    });
    await setStorageVideos(updatedVideos);
    callback();
  });
};

const appendNewVideoToStorage = (video, videoCode, redirectCallBack) => {
  video.videoCode = videoCode;
  if (video.videos && video.videos?.length === 0) {
    video.videos.push({ videoCode: videoCode, state: "offline" });
  }
  if(!video.videos && video.video === null){
    video.video = { videoCode: videoCode, state: "offline" }
  }

  getStorageVideos().then(async (storagedVideos) => {
    if (storagedVideos && storagedVideos !== "null") {
      const storageHasVideo = storagedVideos?.some(
        (storageVideo) => storageVideo.token === video.token
      );
      if (!storageHasVideo) {
        await setStorageVideos([video, ...storagedVideos]);
      }
    } else {
      await setStorageVideos([video]);
    }

    if (redirectCallBack) {
      redirectCallBack();
    }
  });
};

export const removeVideoFromGuestStorageVideoObjects = async (
  videoTokenToRemove,
  callback
) => {
  await getStorageVideos().then(async (storagedVideos) => {
    const videosWithoutVideoTokenToRemove = storagedVideos.filter(
      (storageVideo) => storageVideo.token !== videoTokenToRemove
    );

    await setStorageVideos(videosWithoutVideoTokenToRemove);
    callback();
  });
};

const createTokenString = (storagedVideos) => {
  //creates a sting of tokens from video objects that have a video at the time of merging
  let tokens = [];
  storagedVideos.forEach((storageVideo) => {
    tokens.push(storageVideo.token);
  });
  return tokens.join();
};

export const getStorageVideoObjects = (returnObjects) => {
  getStorageVideos().then((storagedVideos) => {
    returnObjects(storagedVideos);
  });
};

export const useGuestVideoMergeMonitorHook = (user) => {
  const history = useHistory();

  const [mergeVideosToUserProfile] = useMutation(
    attachStorageVideosToUserProfile,
    {
      onSuccess(data) {
        history.push("/user/videos?update");
        clearStorageCode();
        clearStorageVideos();
      },
      onError(error) {
        clearStorageVideos();
        clearStorageCode();
      },
    }
  );

  useEffect(() => {
    if (user) {
      // user has been detected: three things must happen
      getStorageVideos().then((storagedVideos) => {
        if (storagedVideos?.length > 0 && storagedVideos !== "null") {
          const allVideosReady = storagedVideos?.every((stroageVideo) =>
            stroageVideo.videos?.every((video) => video.state !== "offline")
          );
          if (allVideosReady) {
            const storageVideoTokenString = createTokenString(storagedVideos);

            if (storageVideoTokenString.length > 0) {
              //three: merge the available videos to user profile
              mergeVideosToUserProfile(storageVideoTokenString);
            }
          }
        }
      });
    }
  }, [user]);
};

export const useConnection = () => {
  const [connectionSuccess, setConnectionSuccess] = useState(null);
  const [connectionError, setConnectionError] = useState(null);
  const user = useUser();
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useParams();

  const [mergeVideosToUserProfile] = useMutation(
    attachStorageVideosToUserProfile,
    {
      onSuccess(data) {
        history.push("/user/videos?update");
        clearStorageCode();
      },
      onError(error) {
        clearStorageVideos();
        clearStorageCode();
      },
    }
  );

  useEffect(() => {
    if (connectionError) {
      setTimeout(() => {
        setConnectionError(null);
      }, 1000);
    }
  }, [connectionError]);

  //if user signs in, check if there is a storage connection code and connect if true

  const handleConnectionSuccess = () => {
    const scanningModalOpen = query.has("scan");
    if (scanningModalOpen) {
      setConnectionSuccess(true);
      setConnectionError(false);
      clearStorageCode();
    } else {
      history.push("/?scan&success");
    }
  };

  const tryToGetVideo = async (videoCode) => {
    let magicVideo = await getMagicVideo("", { video_code: videoCode });

    if (magicVideo.error) {
      clearStorageCode();

      setTimeout(() => {
        setConnectionError(404);
      }, 1000);
    } else {

      if (user) {
        if (!magicVideo.videos?.length && !magicVideo.video) {
          appendNewVideoToStorage(magicVideo, videoCode, () => {
            clearStorageCode();
            history.push("/user/videos?update");
          });
        } else {
          mergeVideosToUserProfile(magicVideo.token);
        }
      } else {
        appendNewVideoToStorage(magicVideo, videoCode, () => {
          clearStorageCode();
          history.push("/user/videos?update");
        });
      }
    }
  };

  const createConnection = (code) => {
    if (code?.length && code !== "null") {
      saveStorageConnectionCode(code);
      connectWithCode(code).then((res) => {
        if (res.connected_to) {
          handleConnectionSuccess();
        }

        if (res === 404) {
          tryToGetVideo(code);
        }
        if (res === 401) {
          history.push(`${pathname}?sign-in&c`);
        }
      });
    }
  };

  useEffect(() => {
    if (user) {
      getStorageCode().then((storageCode) => {
        if (storageCode !== "null" && storageCode) {
          createConnection(storageCode);
        }
      });
    }
  }, [user]);

  const clearError = () => {
    setConnectionError(false);
  };

  return {
    createConnection,
    connectionSuccess,
    connectionError,
    clearError,
    clearStorageCode,
    saveStorageConnectionCode,
  };
};

export const usePollGuestVideo = (
  video,
  updateGuestVideos,
  updateUserVideos
) => {
  //refetch not quite working

  const user = useUser();
  const history = useHistory();
  const queryCache = useQueryCache();

  const [mergeVideosToUserProfile] = useMutation(
    attachStorageVideosToUserProfile,
    {
      onSuccess(data) {
        updateUserVideos();
      },
      onError(error) {
        clearStorageVideos();
        clearStorageCode();
      },
    }
  );

  const { data: videoData, error } = useQuery(
    ["getMagicVideo", { video_code: video?.videoCode }],
    getMagicVideo,
    {
      refetchOnWindowFocus: false,
      refetchInterval: 3000,
    }
  );

  const addPolledVideosToStorage = async (
    storagedVideos,
    guestCallback,
    userCallback
  ) => {
    let updatedStorageVideos;
    if (!user) {
      updatedStorageVideos = storagedVideos?.map((storageVideo) => {
        if (storageVideo.token === videoData.token) {
          return videoData;
        } else {
          return storageVideo;
        }
      });
    } else {
      updatedStorageVideos = storagedVideos?.filter(
        (storageVideo) => storageVideo.videoCode !== video.videoCode
      );
    }

    await setStorageVideos(updatedStorageVideos).then(() => {
      if (!user) {
        guestCallback();
      } else {
        userCallback();
      }
    });
  };

  useEffect(() => {
    if (videoData && videoData?.videos?.length > 0) {
      getStorageVideos().then(async (storagedVideos) => {
        addPolledVideosToStorage(
          storagedVideos,
          () => {
            updateGuestVideos();
          },
          (tokens) => {
            mergeVideosToUserProfile(videoData.token);
          }
        );
      });
    }
  }, [videoData]);
};
