import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryCache,
} from "react-query";
import { isSameDay } from "date-fns";
import { Capacitor } from "@capacitor/core";
import { useUser } from "../contexts/AuthContext";
import TextBanner from "../components/TextBanner.js";
import FetchMoreButton from "../components/FetchMoreButton.js";
import { clearBadgeNumber } from "../utilities/badge.js";
import {
  getUserNotifications,
  getUnseenNotificationsCount,
  markNotificationsAsSeen,
} from "../queries/queries";
import PullRefresh from "../components/PullToRefresh.js";

import { ReactComponent as Info } from "../images/icons/Info.svg";
import CookieBanner from "../components/CookieBanner.js";
import { ReactComponent as CrownPlay } from "../images/notificationIcons/Icon-crown-play.svg";
import { ReactComponent as CrownDown } from "../images/notificationIcons/Icon-down.svg";
import { ReactComponent as CrownUp } from "../images/notificationIcons/Icon-up.svg";
import { ReactComponent as TwoFlags } from "../images/notificationIcons/Icon-flags.svg";
import { ReactComponent as Party } from "../images/notificationIcons/Icon-party.svg";
import { ReactComponent as PlayCheckmark } from "../images/notificationIcons/Icon-play-check.svg";
import { ReactComponent as CrownHourGlass } from "../images/notificationIcons/Icon-sandglass.svg";
import { ReactComponent as Sparkles } from "../images/notificationIcons/Icon-sparkles.svg";
import { ReactComponent as TwoArrows } from "../images/notificationIcons/Icon-two-arrows.svg";

import styles from "./Notifications.module.css";

const Notifications = () => {
  const { t } = useTranslation();
  const user = useUser();
  const today = useMemo(() => new Date(), []);

  useMarkNotificationsAsSeen();

  const notificationsQueryEnabled = !!user?.id;
  const {
    data: groups,
    fetchMore,
    canFetchMore,
    isFetchingMore,
    refetch: refetchNotifications,
  } = useInfiniteQuery(
    ["userNotifications", { app_user_id: user?.id }],
    getUserNotifications,
    {
      enabled: notificationsQueryEnabled,
      getFetchMore: (lastGroup) =>
        lastGroup?.canFetchMore && { page: lastGroup.nextPage },
    }
  );

  const notifications = groups?.flatMap(({ notifications }) => notifications);

  const [notificationsToday, notificationsEarlier] = (notifications || [])
    .map((notification) => ({
      ...notification,
      date: notification.date ? new Date(notification.date) : today,
    }))
    .reduce(
      (buckets, notification) => {
        buckets[isSameDay(notification.date, today) ? 0 : 1].push(notification);
        return buckets;
      },
      [[], []]
    );

  useEffect(() => {
    if (Capacitor.isNative) {
      clearBadgeNumber();
    }
  });

  return (
    <>
      <TextBanner>
        <b>{t("Notifications.textBanner")}</b>
      </TextBanner>
      <CookieBanner />

      <PullRefresh
        onRefresh={() => {
          if (notificationsQueryEnabled) refetchNotifications();
        }}
      >
        <div className="content">
          <div className={styles.list}>
            {notificationsToday.length > 0 && (
              <>
                <h2 className="h3">{t("DateTime.today")}</h2>
                {notificationsToday.map((notification) => (
                  <Notification key={notification.id} {...notification} />
                ))}
              </>
            )}

            {notificationsEarlier.length > 0 && (
              <>
                <h2 className="h3">{t("Notifications.earlier")}</h2>
                {notificationsEarlier.map((notification) => (
                  <Notification key={notification.id} {...notification} />
                ))}
              </>
            )}

            {!canFetchMore && (
              <Notification
                title={"party"}
                localized_description={t("Notifications.squad")}
                subtitle={t("Notifications.squadSubText")}
                defaultUrl={"/leagues/high-scores"}
              />
            )}
          </div>

          {canFetchMore && (
            <FetchMoreButton
              fetchMore={fetchMore}
              isFetchingMore={isFetchingMore}
            />
          )}
        </div>
      </PullRefresh>
    </>
  );
};
export default Notifications;

const highScoreRedirects = [
  "made_it_to_the_league_top_10",
  "new_league_season",
  "season_is_ending",
  "season_has_ended",
  "league_high_score_was_beat",
];

const getUrl = (title, special_attributes) => {
  if (special_attributes?.video_id) {
    return `/notifications/?post-video&video-id=${special_attributes.video_id}`;
  }

  if (title === "first_connection") {
    return "/";
  }

  const score_id = special_attributes?.score_id;
  const leaderboard_id = special_attributes?.leaderboard_id;
  const redirectToHighScores = highScoreRedirects.some(
    (redirectTitle) => redirectTitle === title
  );

  if (redirectToHighScores) {
    const path = `/leagues/global`;
    const params = new URLSearchParams();
    if (score_id !== undefined) params.set("callout-score", score_id);
    if (leaderboard_id !== undefined)
      params.set("callout-leaderboard", leaderboard_id);
    const paramsString = params.toString();
    return `${path}${paramsString ? `?${paramsString}` : ""}`;
  }

  if (title === "location_high_score_was_beat") {
    const location_id = special_attributes?.location_id;
    const path = "/user/leaderboards";
    const params = new URLSearchParams();
    if (location_id !== undefined) params.set("location_id", location_id);
    if (score_id !== undefined) params.set("callout-score", score_id);
    if (leaderboard_id !== undefined)
      params.set("callout-leaderboard", leaderboard_id);
    const paramsString = params.toString();
    return `${path}${paramsString ? `?${paramsString}` : ""}`;
  }

  if (title === "local_league_high_score_was_beat") {
    const location_handle = special_attributes?.location_handle;

    if (location_handle) {
      const path = `/leagues/local/${location_handle}`;
      const params = new URLSearchParams();
      if (score_id !== undefined) params.set("callout-score", score_id);
      if (leaderboard_id !== undefined)
        params.set("callout-leaderboard", leaderboard_id);
      const paramsString = params.toString();
      return `${path}${paramsString ? `?${paramsString}` : ""}`;
    }
  }

  if (title === "made_it_to_the_local_league_top_10") {
    const location_handle = special_attributes?.location_handle || "";
    return `/leagues/local/${location_handle}`;
  }

  return "/";
};

const renderIcon = (title) => {
  let icon;
  const style = { "--size": "36px" };
  switch (title) {
    case "party":
      icon = <Party className="icon" style={style} />;
      break;
    case "video_is_ready":
      icon = <PlayCheckmark className="icon" style={style} />;
      break;
    case "league_high_score_was_beat":
    case "local_league_high_score_was_beat":
      icon = <CrownDown className="icon" style={style} />;
      break;
    case "made_it_to_the_league_top_10":
    case "made_it_to_the_local_league_top_10":
      icon = <CrownUp className="icon" style={style} />;
      break;
    case "new_league_season":
      icon = <TwoFlags className="icon" style={style} />;
      break;
    case "location_high_score_was_beat":
      icon = <TwoArrows className="icon" style={style} />;
      break;
    case "video_contest_submission_confirmation":
      icon = <CrownPlay className="icon" style={style} />;
      break;
    case "new_game_released":
      icon = <Sparkles className="icon" style={style} />;
      break;
    case "season_has_ended":
      icon = <CrownHourGlass className="icon" style={style} />;
      break;
    case "season_is_ending":
      icon = <CrownHourGlass className="icon" style={style} />;
      break;
    case "new_levels_released":
      icon = <Sparkles className="icon" style={style} />;
      break;
    default:
      icon = <Info className="icon" style={style} />;
      break;
  }

  return icon;
};

const defaultFormatDate = (date) => date.toLocaleString();
export const Notification = ({
  date,
  title,
  localized_description,
  special_attributes,
  defaultUrl,
  subtitle,
  formatDate = defaultFormatDate,
}) => {
  const url = defaultUrl ? defaultUrl : getUrl(title, special_attributes);

  return (
    <Link className={styles.notification} as={Link} to={url}>
      <div className={styles.icon}>{renderIcon(title)}</div>
      <div className={styles.body}>
        <p className="tiny">{localized_description}</p>
        {subtitle && <p className="tiny">{subtitle}</p>}
        <p className="tiny">
          <time dateTime={date?.toString()} title={date?.toLocaleString()}>
            {date && formatDate(date)}
          </time>
        </p>
      </div>
    </Link>
  );
};

export const useNewNotificationsCount = () => {
  const user = useUser();

  const { data } = useQuery(
    "unseenNotificationsCount",
    getUnseenNotificationsCount,
    { enabled: !!user }
  );

  return data?.amount_of_unseen_notifications || 0;
};

export const useMarkNotificationsAsSeen = () => {
  const user = useUser();
  const queryCache = useQueryCache();
  const count = useNewNotificationsCount();

  const [mutate] = useMutation(markNotificationsAsSeen, { enabled: !!user });

  useEffect(() => {
    if (user && count > 0) {
      mutate().then(() => {
        queryCache.invalidateQueries("unseenNotificationsCount");
      });
    }
  }, [user, count, mutate, queryCache]);
};
