import styles from "./TextBanner.module.css";

const TextBanner = ({ children }) => {
  return (
    <div className={`${styles.container} always-full-width small`}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
export default TextBanner;
