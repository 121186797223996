import CookieBanner from "react-cookie-banner";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";

import Button from "../components/Button.js";

import styles from "./CookieBanner.module.css";

const OurCookieBanner = () => {
  const { t } = useTranslation();

  // The wrapper div prevents the banner from breaking the app grid layout
  return (
    <div>
      {Capacitor.isNative ? null : (
        <CookieBanner disableStyle={true} cookie="user-has-accepted-cookies">
          {(onAccept) => (
            <div className={`${styles.container} small`}>
              <p>{t("CookieBanner.text")}</p>
              <Button variant="primary" onClick={onAccept} small>
                {t("CookieBanner.buttonText")}
              </Button>
            </div>
          )}
        </CookieBanner>
      )}
    </div>
  );
};
export default OurCookieBanner;
