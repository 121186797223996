import { useTranslation } from "react-i18next";

import Button from "../components/Button.js";
import Spinner from "../components/Spinner.js";

const FetchMoreButton = ({ fetchMore, isFetchingMore }) => {
  const { t } = useTranslation();

  return (
    <Button
      as="button"
      variant="secondary"
      onClick={() => fetchMore()}
      trackingLabel="fetch_more"
      disabled={isFetchingMore}
    >
      <span>
        {t("FetchMoreButton.loadMore")}{" "}
        {isFetchingMore && <Spinner size={16} />}
      </span>
    </Button>
  );
};
export default FetchMoreButton;
