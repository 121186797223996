import Honeybadger from "@honeybadger-io/js";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { getStorageVideosJSON } from "./utilities/storageFunctions";
const honeybadger = Honeybadger.configure({
  apiKey: "a29cf3f0",
  environment:
    process.env.REACT_APP_API_ROOT === "https://cloud.vm-staging.com"
      ? "staging"
      : "production",
  reportData: process.env.NODE_ENV !== "development",
});
export default honeybadger;

honeybadger.setContext({
  platform: Capacitor.getPlatform(),
});

if (Capacitor.isNative) {
  (async () => {
    const info = await App.getInfo();
    getStorageVideosJSON().then((data) => {
      honeybadger.setContext({
        capacitor: info,
        storage: data,
      });
    });
  })();
}
