import Button from "../components/Button.js";
import Spinner from "../components/Spinner.js";
import { disconnect } from "../queries/queries";
import { useMutation } from "react-query";
import { useConnection } from "../utilities/codeHooks.js";

const DisconnectButton = ({ children, ...props }) => {
  const { clearStorageCode } = useConnection();

  const [doDisconnect, { isLoading, isSuccess }] = useMutation(disconnect, {
    onSuccess(data) {
      clearStorageCode();
      //  window.location.reload();
    },
    onError(data) {
      console.log("error", data);
    },
  });

  const disconnectConnection = () => {
    doDisconnect();
  };

  return (
    <>
      <Button onClick={() => disconnectConnection()} {...props}>
        {children}
        <>&nbsp;</> {/* Sorry, no time to write custom CSS for this */}
        {(isLoading || isSuccess) && <Spinner size={16} />}
      </Button>
    </>
  );
};
export default DisconnectButton;
