import { lazy } from "react";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";

import Spinner from "./Spinner.js";

import "./PullToRefresh.css";

const PullToRefresh = lazy(() => import("react-simple-pull-to-refresh"));

const PullRefresh = ({
  spinnerColor,
  spinnerSize = 24,
  refreshFunction,
  fetchMoreThreshold = 48,
  maxPullDownDistance = 96,
  onRefresh,
  canFetchMore = true,
  isPullable,
  pullingContent,
  refreshingContent = <Spinner color={spinnerColor} size={spinnerSize} />,
  className = "content no-padding-top-when-narrow",
  children,
}) => {
  const { t } = useTranslation();

  return Capacitor.isNative ? (
    <PullToRefresh
      onRefresh={
        onRefresh
          ? () =>
              new Promise((res) => {
                res(onRefresh());
              })
          : undefined
      }
      canFetchMore={canFetchMore}
      fetchMoreThreshold={fetchMoreThreshold}
      pullingContent={pullingContent || t("Home.pullToRefresh")}
      refreshingContent={refreshingContent}
      maxPullDownDistance={maxPullDownDistance}
      className={className}
    >
      {children}
    </PullToRefresh>
  ) : (
    <div className={className}>{children}</div>
  );
};
export default PullRefresh;
