import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import DisconnectButton from "../components/DisconnectButton.js";
import { ReactComponent as Signal } from "../images/icons/Signal.svg";
import { useUser } from "../contexts/AuthContext.js";
import { reportEvent } from "../components/GoogleTagManager.js";

import styles from "./ConnectionHeader.module.css";

const ConnectionHeader = ({ connection }) => {
  const { t } = useTranslation();
  const user = useUser();
  const { connected_at, connected_to } = connection || {};

  //console.log("ConnectionHeader", connection);

  useEffect(() => {
    if (connected_at || connected_to) {
      reportEvent({ event: "connection_started", connected_at, connected_to });
      return () =>
        reportEvent({ event: "connection_ended", connected_at, connected_to });
    }
  }, [connected_at, connected_to]);

  let loading = !connection?.connected_at && connection?.connected_to;

  if (connection?.connected_to) {
    return (
      <div className={styles.container}>
        <div className={styles.status}>
          <Signal className="icon" style={{ "--size": "18px" }} />
          <div>
            <p>
              <strong>
                {loading
                  ? t("ConnectionHeader.connecting")
                  : t("ConnectionHeader.connected")}
              </strong>
            </p>
            <p>{connection.connected_to}</p>
          </div>
        </div>
        <div>
          <DisconnectButton
            connected_at={connection?.connected_at}
            variant="tertiary"
            small
            style={{
              "--color": "var(--navy)",
            }}
          >
            {t("ConnectionHeader.disconnect")}
          </DisconnectButton>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default ConnectionHeader;
