import React, {
  useEffect,
  lazy,
  Suspense,
  useRef,
  createContext,
  useContext,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import LoadingScreen from "../components/LoadingScreen.js";
import Button from "../components/Button.js";
import ErrorBoundary from "../components/ErrorBoundary.js";
import { ReactComponent as Cross } from "../images/icons/Cross.svg";
import { ReactComponent as ChevronLeft } from "../images/icons/ChevronLeft.svg";
import useParams from "../utilities/useParams.js";
import { useUser } from "../contexts/AuthContext";
import { StatusBarStyle, StatusBar } from "@capacitor/status-bar";
import styles from "./Modals.module.css";

export const modals = {
  welcome: lazy(() =>
    import("./WelcomeModal.js")),
  video: lazy(() =>
    import("./VideoModal.js")),
  report: lazy(() =>
    import("./ReportingModal.js")),
  "post-video": lazy(() =>
    import("./VideoPostingModal.js")),
  scan: lazy(() =>
    import("./ScanningModal.js")),
  "sign-in": lazy(() =>
    import("./Login.js")),
  "sign-in-with-email": lazy(() =>
    import("./SignInWithEmail.js")),
  "sign-up": lazy(() =>
    import("./Register.js")),
  "country-feed": lazy(() =>
    import("./CountryFeedModal.js")),
  "game-feed": lazy(() =>
    import("./TagFeedModal.js")),
  "location-feed": lazy(() =>
    import("./LocationFeedModal.js")),
  "user-feed": lazy(() =>
    import("./UserFeedModal.js")),
  "forgot-password": lazy(() =>
    import("./ForgotPassword.js")),
  "change-password": lazy(() =>
    import("./ChangePassword.js")),
  "reset-password": lazy(() =>
    import("./ResetPassword.js")),
  "video-contest": lazy(() =>
    import("./ContestSubmitAd.js")),
  "video-contest-choose": lazy(() =>
    import("./ContestSubmitVideoList.js")),
  "video-contest-submit": lazy(() =>
    import("./ContestVideoModal.js")),
  "edit-profile": lazy(() =>
    import("./ProfileEditingModal.js")),
  "change-league": lazy(() =>
    import("./ChangeLeagueModal.js")),
  "update-prompt": lazy(() =>
    import("./UpdatePromptModal.js")),
};

export const getActiveModalName = (query) => {
  return Object.keys(modals).find(
    (name) => query.has(name) && !query.get(name)
  );
};

const supportsOverflowContain = CSS.supports("overscroll-behavior", "contain");

const ModalContext = createContext();
export const useModal = () => useContext(ModalContext);

/**
 * The application uses URL query parameters to dynamically control the rendering of various modals,
 * leveraging React Router's `useParams` and lazy loading of components. This approach enables deep linking,
 * allowing users to navigate directly to specific modals by using a URL. When a query parameter matches
 * a key in the `modals` object, the corresponding modal is lazily loaded and rendered. This method supports
 * bookmarking, sharing, and reloading of specific application states, enhancing user experience by providing
 * direct access to modals based on the browser's URL.
 *
 * @example
 * // Navigating to /path?video will open the VideoModal
 * // Navigating to /path?report will open the ReportingModal
 *
 * @summary
 * | Query Param               | Triggered Modal             |
 * |---------------------------|-----------------------------|
 * | `video`                   | `VideoModal`                |
 * | `report`                  | `ReportingModal`            |
 * | `post-video`              | `VideoPostingModal`         |
 * | `video-contest`           | `ContestSubmitAd`           |
 * | `video-contest-choose`    | `ContestSubmitVideoList`    |
 * | `video-contest-submit`    | `ContestVideoModal`         |
 * | `edit-profile`            | `ProfileEditingModal`       |
 * | `change-league`           | `ChangeLeagueModal`         |
 * | `update-prompt`           | `UpdatePromptModal`         |
 * | `sign-in`                 | `Login`                     |
 * | `sign-in-with-email`      | `SignInWithEmail`           |
 * | `sign-up`                 | `Register`                  |
 * | `country-feed`            | `CountryFeedModal`          |
 * | `game-feed`               | `TagFeedModal`              |
 * | `location-feed`           | `LocationFeedModal`         |
 * | `user-feed`               | `UserFeedModal`             |
 * | `forgot-password`         | `ForgotPassword`            |
 * | `change-password`         | `ChangePassword`            |
 * | `reset-password`          | `ResetPassword`             |
 */

const Modals = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const user = useUser();

  const query = useParams();
  const mode = getActiveModalName(query);

  const dialogRef = useRef();

  useEffect(() => {
    if (Capacitor.isNative) {
      let style;

      switch (mode) {
        case undefined:
        case null:
        case "welcome":
        case "report":
        case "post-video":
        case "video-contest":
        case "video-contest-choose":
        case "video-contest-submit":
          style = StatusBarStyle.Dark;
          break;
        default:
          style = StatusBarStyle.Light;
      }

      StatusBar.setStyle({
        style,
      });
    }
  }, [mode]);

  useEffect(() => {
    if (mode) {
      if (dialogRef.current) {
        dialogRef.current.focus();
      }

      const handleKeyDown = (event) => {
        switch (event.code || event.key) {
          case "Escape":
          case "Esc":
            if (event.target === dialogRef.current) {
              history.push({ pathname });
            }
            break;
          default:
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [mode, history, pathname]);

  useEffect(() => {
    if (mode && !supportsOverflowContain) {
      const { pageXOffset, pageYOffset } = window;
      document.body.style.setProperty("overflow", "hidden");

      return () => {
        document.body.style.removeProperty("overflow");
        window.scrollTo(pageXOffset, pageYOffset);
      };
    }
  });

  useEffect(() => {
    if ((user && mode === "sign-in") || (user && mode === "sign-up")) {
      history.push(pathname);
    }
  });

  const ModalComponent = modals[mode];
  const queryAsProps = [...query.entries()].reduce((props, [key, value]) => {
    props[key] = value;
    return props;
  }, {});

  return mode ? (
    <ModalContext.Provider value={dialogRef}>
      <section role="dialog" aria-modal="true" className={`${styles.modal} ${(mode && styles[mode]) || ""}`} ref={dialogRef} tabIndex="-1">
        <div className="content">
          <ErrorBoundary inline={false}>
            <Suspense fallback={<LoadingScreen />}>
              {ModalComponent && <ModalComponent {...queryAsProps} />}
            </Suspense>
          </ErrorBoundary>
        </div>
      </section>
    </ModalContext.Provider>
  ) : null;
};

export default Modals;

const headerButtonStyles = (dark) => ({
  "--color": dark ? "var(--mint)" : "var(--blue)",
  "--background-color": dark ? "var(--faint-mint)" : "#e0e4f8",
});

export const CloseButton = ({ dark = false, ...rest }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Button as="Link" variant="secondary" iconOnly round style={headerButtonStyles(dark)} to={{ pathname }} {...rest}>
      <Cross className="icon" aria-label={t("Modals.close")} title={t("Modals.close")} />
    </Button>
  );
};

export const ModalHeader = ({
  children,
  dark = false,
  backTo = false,
  removeTopGap = false,
  showClose = true,
}) => {
  const { t } = useTranslation();

  return (
    <header className={`always-full-width ${styles.header} ${dark ? styles.dark : ""} ${backTo ? styles["has-back"] : ""} ${removeTopGap ? styles["remove-top-gap"] : ""} ${!children ? styles.transparent : ""}`}>
      <div className={styles["header-inner"]}>
        {backTo && (
          <Button iconOnly round small style={headerButtonStyles(dark)} as="Link" variant="secondary" to={backTo}>
            <ChevronLeft className="icon" aria-label={t("Modals.back")} title={t("Modals.back")} />
          </Button>
        )}
        <div className={`small ${styles["header-content"]}`}>{children}</div>
        {showClose && <CloseButton small dark={dark} />}
      </div>
    </header>
  );
};