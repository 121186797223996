import { Badge } from "@capawesome/capacitor-badge";

export const getBadgeNumber = async () => {
  const badgeNumber = await Badge.get();
  return badgeNumber.count;
};

export const setBadgeNumber = async (count) => {
  await Badge.set({ count });
};

export const clearBadgeNumber = async () => {
  await Badge.clear();
};

export const handleBadgenumberDecrease = async () => {
  const count = await getBadgeNumber();
  setBadgeNumber(count - 1);
};
