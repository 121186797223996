import "@capacitor/core";
import "react-app-polyfill/stable";
import { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import GoogleTagManager from "./components/GoogleTagManager.js";
import ErrorBoundary from "./components/ErrorBoundary.js";
import "./localization.js";
import "./honeybadger.js";
import AppProviders from "./contexts";
import "./default.css";

import App from "./App.js"; // non-lazy intentionally, to not impact player view load times
const Scoreboards = lazy(() => import("./views/Scoreboards.js"));

ReactDOM.render(
  <Suspense fallback={null}>
    <Router>
      <ErrorBoundary inline={false}>
        <AppProviders>
          <Switch>
            <Route path="/scoreboards/:locationHandle/:ids?">
              <Scoreboards />
            </Route>
            <Route>
              <App />
            </Route>
          </Switch>
        </AppProviders>

        <GoogleTagManager />
      </ErrorBoundary>
    </Router>
  </Suspense>,
  document.getElementById("root")
);
