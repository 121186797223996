import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useHighlightRipple } from "../components/HighlightRipple.js";
import { useNewNotificationsCount } from "../views/Notifications.js";

import { ReactComponent as Home } from "../images/icons/Home.svg";
import { ReactComponent as Crown } from "../images/icons/Crown.svg";
import { ReactComponent as User } from "../images/icons/User.svg";
import { ReactComponent as Bell } from "../images/icons/Bell.svg";
import { ReactComponent as Pin } from "../images/icons/Pin.svg";

import styles from "./Navigation.module.css";

const NavigationLink = ({
  to,
  children,
  exact = false,
  Icon,
  badge,
  ...rest
}) => (
  <li className={styles.item}>
    <NavLink
      className={styles.link}
      activeClassName={styles.active}
      to={to}
      exact={exact}
      {...rest}
    >
      <span className={styles["icon-and-badge"]}>
        {Icon ? <Icon className="icon" style={{ "--size": "20px" }} /> : null}
        {badge}
      </span>
      {children}
    </NavLink>
  </li>
);

const Navigation = ({ connection }) => {
  const { t } = useTranslation();
  const [rippleElement, triggerRipple] = useHighlightRipple();

  const newNotifications = useNewNotificationsCount();

  return (
    <nav
      className={`${styles.container} ${
        connection?.connected_to ? styles.connected : ""
      }`}
      onTouchEnd={triggerRipple}
      onClick={triggerRipple}
    >
      <ul className={styles.list}>
        <NavigationLink to="/" Icon={Home} exact>
          {t("Navigation.home")}
        </NavigationLink>
        <NavigationLink to="/map" Icon={Pin} exact>
          {t("Navigation.map")}
        </NavigationLink>
        <NavigationLink to="/leagues" Icon={Crown}>
          {t("Navigation.leagues")}
        </NavigationLink>
        <NavigationLink to="/user" Icon={User}>
          {t("Navigation.user")}
        </NavigationLink>
        <NavigationLink
          to="/notifications"
          Icon={Bell}
          badge={
            newNotifications > 0 && (
              <span
                className={styles.badge}
                aria-label={t("Navigation.youHaveNewNotifications")}
              >
                {newNotifications < 10 ? newNotifications : null}
              </span>
            )
          }
        >
          {t("Navigation.notifications")}
        </NavigationLink>
      </ul>

      {rippleElement}
    </nav>
  );
};
export default Navigation;
