import wretch from "wretch";
import { useQuery } from "react-query";

import { useSession } from "./contexts/AuthContext";

const API_ROOT = process.env.REACT_APP_API_ROOT || "";

export function apiUrl(url) {
  return `${API_ROOT}/api/v1/${url}`;
}

function get(url, session) {
  return wretch(apiUrl(url)).headers(session).get().json();
}

// Call to test the API
export function useTests() {
  const session = useSession();

  return useQuery("tests", () => get("tests", session));
}
