import wretch from "wretch";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";

import { getSessionCookie } from "../contexts/AuthContext";

const HOST = process.env.REACT_APP_API_ROOT;
const QUERY_VERSION = process.env.REACT_APP_QUERY_VERSION;

// Set client info for query headers (used for OldVersionError in the backend)
export const clientHeaders = {
  "ValoApp-Platform": "web",
};

if (Capacitor.isNative) {
  (async () => {
    const { version } = await App.getInfo();
    clientHeaders["ValoApp-Version"] = version;
    clientHeaders["ValoApp-Platform"] = Capacitor.getPlatform();
  })();
}

export const getHeaders = async () => {
  const cookie = await getSessionCookie();
  return { ...cookie, ...clientHeaders };
};

//magic links

export const attachStorageVideosToUserProfile = async (tokens) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/guest_videos?tokens=${tokens}`)
    .headers(headers)
    .post()
    .json();
};

export const getMagicVideo = async (key, { video_code }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/guest_videos/${video_code}`)
    .headers(headers)
    .get()
    .notFound(() => {
      return {
        error: {
          status: 404,
          video_code: video_code,
        },
      };
    })
    .json();
};

export const getMagicVideos = async (guestVideoTokens) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/videos?tokens=${guestVideoTokens}`)
    .headers(headers)
    .get()
    .json();
};

export const setGuestVideoPrivacy = async ({ token, id, isPublic }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/videos/${id}?tokens=${token}`)
    .headers(headers)
    .put({
      video: {
        public: isPublic,
      },
    })
    .json();
};

export const deleteGuestVideo = async ({ videoId, token }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/videos/${videoId}?tokens=${token}`)
    .headers(headers)

    .delete()
    .notFound((error) => error);
};

// pushnotifs

export const updateDeviceToken = async (device_token) => {
  let headers = await getHeaders();
  return await wretch(
    `${HOST}/api/v1/device_tokens/update_device_token?device_token=${device_token}`
  )
    .headers(headers)
    .put()
    .json();
};

// video reporting

export const reportVideo = async (attributes) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/video_report`)
    .headers(headers)
    .post(attributes)
    .json();
};

//connections

export const connectWithCode = async (connection_code) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/connections/${connection_code}`)
    .headers(headers)
    .put()
    .notFound((error) => {
      return error.status;
    })
    .unauthorized((error) => {
      return error.status;
    })
    .json();
};

export const disconnect = async () => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/connections`)
    .headers(headers)
    .delete()
    .json();
};

export const getConnections = async () => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/connections`)
    .headers(headers)
    .get()
    .unauthorized((error) => {
      return null; //dont do anything
    })
    .json();
};

//other

const mergeParameters = (base, additional) => {
  for (const entry of additional) {
    if (typeof entry === "object") {
      Object.assign(base, entry);
    }
  }
  return base;
};
const parametersIntoString = (parameters) =>
  Object.entries(parameters)
    .filter(([key, value]) => value !== null && value !== undefined)
    .map(
      ([key, value]) =>
        `${key}=${typeof value === "object" ? value.toString() : value}`
    )
    .join("&");

export const getFeed = async (key, ...parametersList) => {
  const parameters = mergeParameters({ page: 1, per_page: 8 }, parametersList);
  const parametersString = parametersIntoString(parameters);

  let headers = await getHeaders();
  const result = await wretch(`${HOST}/api/v1/feed?v=v2&${parametersString}`)
    .headers(headers)
    .get()
    .json();

  return {
    ...result,
    nextPage: parameters.page + 1,
    canFetchMore: result?.load_more,
  };
};
export const getVideoFeed = async (key, ...parametersList) => {
  const parameters = mergeParameters(
    { page: 1, per_page: 8, v: QUERY_VERSION },
    parametersList
  );
  const parametersString = parametersIntoString(parameters);
  let headers = await getHeaders();
  const result = await wretch(`${HOST}/api/v1/videos?${parametersString}`)
    .headers(headers)
    .get()
    .notFound(() => {
      return null;
    })
    .json();

  return {
    videos: result.videos,
    nextPage: parameters.page + 1,
    canFetchMore: result?.load_more,
  };
};

export const getUserNotifications = async (key, ...parametersList) => {
  const parameters = mergeParameters(
    { page: 1, per_page: 10, v: QUERY_VERSION },
    parametersList
  );
  const parametersString = parametersIntoString(parameters);

  let headers = await getHeaders();
  const notifications = await wretch(
    `${HOST}/api/v1/app_notifications?v=v3&${parametersString}`
  )
    .headers(headers)
    .get()
    .notFound(() => {
      return { error: "not found" };
    })
    .json();

  return {
    notifications: notifications.notifications,
    nextPage: parameters.page + 1,
    canFetchMore: notifications?.load_more,
  };
};

export const videoLoadIncrement = async (video_id) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/video_page_loads/${video_id}`)
    .headers(headers)
    .put()
    .json();
};

export const getUserVideos = async (key, ...parametersList) => {
  const parameters = mergeParameters(
    { page: 1, per_page: 10, v: QUERY_VERSION },
    parametersList
  );
  const parametersString = parametersIntoString(parameters);

  let headers = await getHeaders();
  const videos = await wretch(`${HOST}/api/v1/videos?${parametersString}`)
    .headers(headers)
    .get()
    .json();

  return {
    videos: videos?.videos,
    nextPage: parameters.page + 1,
    canFetchMore: videos?.load_more,
  };
};

export const getUserHistory = async (key, ...parametersList) => {
  const parameters = mergeParameters(
    { page: 1, per_page: 10, v: QUERY_VERSION },
    parametersList
  );
  const parametersString = parametersIntoString(parameters);

  let headers = await getHeaders();
  const history = await wretch(`${HOST}/api/v1/history?${parametersString}`)
    .headers(headers)
    .get()
    .json();

  return {
    history: history.scores,
    nextPage: parameters.page + 1,
    canFetchMore: history?.load_more,
  };
};

export const getSeasons = async () => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/seasons`).headers(headers).get().json();
};

export const getSeasonContests = async (key, { season_id }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/contests?season_id=${season_id}`)
    .headers(headers)
    .get()
    .json();
};

export const getSeasonLeaderboards = async (key, { season_id }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/leaderboards?season_id=${season_id}&v=v2`)
    .headers(headers)
    .get()
    .json();
};

export const getContests = async (key, { id }) => {
  let headers = await getHeaders();
  return await wretch(
    `${HOST}/api/v1/contests${id === undefined ? "" : `/${id}`}`
  )
    .headers(headers)
    .get()
    .json();
};

export const submitToContests = async (attributes) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/contest_video`)
    .headers(headers)
    .post(attributes)
    .json();
};

export const getLeaderboard = async (leaderboard_id) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/leaderboards/${leaderboard_id}?v=v2`)
    .headers(headers)
    .get()
    .json();
};

export const getUserLeaderboardsByLocation = async (key, { location_id }) => {
  let headers = await getHeaders();
  return await wretch(
    `${HOST}/api/v1/user_leaderboards?location_id=${location_id}`
  )
    .headers(headers)
    .get()
    .json();
};

export const getLocations = async () => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/locations`).headers(headers).get().json();
};

export const getLocationMarkers = async () => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/location_markers`)
    .headers(headers)
    .get()
    .json();
};

export const getLocation = async (id) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/locations/${id}`)
    .headers(headers)
    .get()
    .json();
};

export const getLocalLeagueLeaderboards = async (handle, season_id) => {
  let headers = await getHeaders();
  return await wretch(
    `${HOST}/api/v1/client_groups/${handle}?v=v2${season_id !== undefined ? `&season_id=${season_id}` : ""
    }`
  )
    .headers(headers)
    .get()
    .json();
};

export const patchUser = async ({ id, attributes }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/user?id=${id}`)
    .headers(headers)
    .put(attributes)
    .json();
};

export const deleteUser = async (id) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/user?id=${id}`)
    .headers(headers)
    .delete()
    .json();
};

export const requestPasswordChange = async (attributes) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/auth/password`)
    .headers(headers)
    .post(attributes)
    .json();
};

export const changePassword = async ({ app_user_id, attributes }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/reset_password/${app_user_id}`)
    .headers(headers)
    .put(attributes)
    .json();
};

export const resetPassword = async (attributes) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/auth/password`)
    .headers(headers)
    .patch(attributes)
    .json();
};

export const getVideo = async (key, { video_id }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/videos/${video_id}`)
    .headers(headers)
    .get()
    .notFound((error) => {
      console.log(error);
      return { error: "not found" };
    })
    .json();
};

export const getGuestVideo = async (key, { video_id, token }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/videos/${video_id}?tokens=${token}`)
    .headers(headers)
    .get()
    .notFound((error) => {
      console.log(error);
      return { error: "not found" };
    })
    .json();
};

export const getAvatars = async () => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/avatars`).headers(headers).get().json();
};

export const setVideoPrivacy = async ({ id, isPublic, app_user_id }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/videos/${id}?app_user_id=${app_user_id}`)
    .headers(headers)
    .put({
      video: {
        public: isPublic,
      },
    })
    .json();
};

export const deleteVideo = async ({ videoId, app_user_id }) => {
  let headers = await getHeaders();
  return await wretch(
    `${HOST}/api/v1/videos/${videoId}/?app_user_id=${app_user_id}`
  )
    .headers(headers)
    .delete();
};

export const getRules = async (key, { season_id }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/rules?season_id=${season_id}`)
    .headers(headers)
    .get()
    .json();
};

export const getUser = async (key, { id }) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/user?id=${id}`)
    .headers(headers)
    .get()
    .json();
};

export const getUnseenNotificationsCount = async () => {
  let headers = await getHeaders();
  return await wretch(
    `${HOST}/api/v1/app_notifications/unseen_notification_counts`
  )
    .headers(headers)
    .get()
    .json();
};

export const markNotificationsAsSeen = async () => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/app_notifications/mark_all_as_seen`)
    .headers(headers)
    .put()
    .json();
};
export const getProducts = async () => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/products`).headers(headers).get().json();
};

export const getUpdateStatus = async (locale, version) => {
  let headers = await getHeaders();
  return await wretch(`${HOST}/api/v1/versions/${version}?locale=${locale}`)
    .headers(headers)
    .get()
    .json();
};
