import { useCallback } from "react";
import { Link } from "react-router-dom";

import { reportEvent } from "../components/GoogleTagManager.js";
import styles from "./Button.module.css";

const Button = ({
  as = "button",
  type = as === "button" ? "button" : undefined,
  variant = "link",
  children,
  className = "",
  round = false,
  small = false,
  arrow = false,
  iconOnly = false,
  allCaps = false,
  active = false,
  to,
  onClick,
  trackingLabel,
  ...rest
}) => {
  // Render a link if `as` is set to "Link" and `to` is present.
  const disabled = (as === "Link" && !to) || rest.disabled;
  const Element = as === "Link" ? (disabled ? "button" : Link) : as;

  const handleClick = useCallback(
    (event) => {
      if (onClick) {
        onClick();
      }
      if (trackingLabel) {
        reportEvent({
          event: "button_clicked",
          label: trackingLabel,
        });
      }
    },
    [onClick, trackingLabel]
  );

  return (
    <Element
      className={`
        ${styles.button}
        ${styles[variant] || ""}
        ${round ? styles.round : ""}
        ${small ? styles.small : ""}
        ${allCaps ? "all-caps" : ""}
        ${arrow ? styles.arrow : ""}
        ${iconOnly ? styles["icon-only"] : ""}
        ${active ? styles.active : ""}
        ${className}
      `}
      type={type}
      to={to}
      disabled={disabled ? true : undefined}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </Element>
  );
};
export default Button;
