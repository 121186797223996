import { createContext, useState, useEffect, useContext } from "react";
import wretch from "wretch";
import { Preferences } from "@capacitor/preferences";
import { useTranslation } from "react-i18next";
//import Cookies from "js-cookie";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { updateDeviceToken } from "../queries/queries";
import { apiUrl } from "../api.js";
import honeybadger from "../honeybadger.js";
import { ToastContainer, toast } from "react-toastify";

async function getSessionCookie() {
  /*
  try {
    const cookieValue = Cookies.get("valo-session");
    return cookieValue && JSON.parse(cookieValue);
  } catch {
    return null;
  }
  */
  const session = await Preferences.get({ key: "valo-session" });
  const parsedSession = JSON.parse(session.value);
  return parsedSession;
}

async function setSessionCookie(session) {
  //  Cookies.set("valo-session", JSON.stringify(session), { expires: 365 });
  const stringify = JSON.stringify(session);

  await Preferences.set({
    key: "valo-session",
    value: stringify,
  });
}

function removeSessionCookie() {
  //Cookies.remove("valo-session");

  const removeSession = async () => {
    await Preferences.remove({ key: "valo-session" });
  };
  removeSession();
}

const AuthContext = createContext();
const API_ROOT = process.env.REACT_APP_API_ROOT;
const APP_HOST = process.env.REACT_APP_HOST;

wretch()
  .errorType("json")
  .accept("application/json")
  .content("application/json");

const authClient = {
  signIn(form) {
    return wretch(apiUrl("auth/sign_in"))
      .post(form)
      .unauthorized((error) => {
        return error.json;
      })
      .res((response) => {
        if (response.ok) {
          return response.json().then(({ data }) => {
            const session = {
              "access-token": response.headers.get("access-token"),
              client: response.headers.get("client"),
              uid: response.headers.get("uid"),
            };
            return { success: true, data, session };
          });
        } else {
          return response.json().then((data) => {
            return { success: false, data };
          });
        }
      });
  },

  openBrowser(url, service, t, history) {
    const browserSettings = {
      toolbarposition: "top",
      toolbartranslucent: "no",
      hidenavigationbuttons: "yes",
      location: "no",

      toolbarcolor: "#022548",
      closebuttoncolor: "#ffffff",
      presentationstyle: "pagesheet",
      footer: "yes",
      footercolor: "#022548",
    };

    var ref = InAppBrowser.create(
      url,
      Capacitor.isNative ? "_blank" : "_self",
      browserSettings
    );

    if (Capacitor.isNative) {
      ref.on("loadstart").subscribe((event) => {
        const eventUrl = new URL(event.url);
        const params = new URLSearchParams(eventUrl.search);

        const auth_token = params.get("auth_token");
        const client_id = params.get("client_id");
        const uid = params.get("uid");
        const msg = params.has("msg");

        if (auth_token && client_id && uid) {
          ref.close();
          setAppleSession(auth_token, client_id, uid);
          history.push({});
          window.location.reload();
          return;
        }

        if (msg) {
          ref.close();
          let text = params.get("msg");
          toast(<p style={{ color: "#022548" }}>{t(text)}</p>);
        }
      });
    }
  },

  signInApple(locale, t, history) {
    const url = `${API_ROOT}/api/v1/auth/apple?auth_origin_url=${APP_HOST}&locale=${locale}`;
    this.openBrowser(url, "apple", t, history);
  },

  signInGoogle(locale, t, history) {
    const url = `${API_ROOT}/api/v1/auth/google_oauth2?auth_origin_url=${APP_HOST}&locale=${locale}`;
    this.openBrowser(url, "google", t, history);
  },
  signInFacebook(locale, t, history) {
    const url = `${API_ROOT}/api/v1/auth/facebook?auth_origin_url=${APP_HOST}&locale=${locale}`;
    this.openBrowser(url, "fb", t, history);
  },
  async signOut() {
    updateDeviceToken(null);
    const headers = await getSessionCookie();
    return wretch(apiUrl("auth/sign_out"))
      .headers(headers)
      .delete()
      .notFound((error) => {
        return error.json;
      })
      .unauthorized((error) => {
        return error.json;
      })
      .json();
  },
  register(form) {
    return wretch(apiUrl("auth"))
      .post(form)
      .unauthorized((error) => {
        console.log("ERROR", error);
        return error.json;
      })
      .res((response) => {
        if (response.ok) {
          return response.json().then(({ data }) => {
            const session = {
              "access-token": response.headers.get("access-token"),
              client: response.headers.get("client"),
              uid: response.headers.get("uid"),
            };
            return { success: true, data, session };
          });
        } else {
          return response.json().then((data) => {
            return { success: false, data };
          });
        }
      });
  },
};

const validateSession = async (body) => {
  const paramsString = Object.entries(body)
    .reduce((params, [key, value]) => {
      return params.concat(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }, [])
    .join("&");

  return wretch(apiUrl(`auth/validate_token?${paramsString}`))
    .get()
    .unauthorized(() => {
      return { success: false };
    })
    .json()
    .catch((error) => {
      honeybadger.notify(error);
      return { success: false };
    });
};

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [initialLoadPerformed, setInitialLoadPerformed] = useState(false);
  useEffect(() => honeybadger.setContext({ user }), [user]);

  useEffect(() => {
    if (!initialLoadPerformed) {
      getSessionCookie().then((existingSession) => {
        if (existingSession) {
          validateSession(existingSession).then((response) => {
            if (response.success) {
              setUser(response.data);
            }

            setInitialLoadPerformed(true);
          });
        } else {
          setInitialLoadPerformed(true);
        }
      });
    }
  }, [initialLoadPerformed]);

  const signIn = (form) =>
    authClient.signIn(form).then((response) => {
      if (response.success) {
        setSessionCookie(response.session);
        setUser(response.data);
      }

      return response;
    });

  const register = (form) =>
    authClient.register({ app_user: form }).then((response) => {
      if (response.success) {
        setSessionCookie(response.session);
        setUser(response.data);
      }

      return response;
    });
  const signOut = () =>
    authClient.signOut().then(() => {
      removeSessionCookie();
      setUser(null);
    });

  // After authenticating, activate the server's locale
  const { i18n } = useTranslation();
  const userLocale = user?.locale;
  useEffect(() => {
    if (userLocale) {
      localStorage.setItem("language", userLocale);
      i18n.changeLanguage(userLocale);
    }
  }, [userLocale, i18n]);

  if (!initialLoadPerformed) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signInGoogle: (locale, t, history) =>
          authClient.signInGoogle(locale, t, history),
        signInApple: (locale, t, history) =>
          authClient.signInApple(locale, t, history),
        signInFacebook: (locale, t, history) =>
          authClient.signInFacebook(locale, t, history),
        signOut,
        register,
        setUser,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useSession() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a AuthProvider`);
  }
  return getSessionCookie();
}

function useUser() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a AuthProvider`);
  }
  return context.user;
}

function setAppleSession(auth_token, client_id, uid) {
  const session = {
    "access-token": auth_token,
    client: client_id,
    uid: uid,
  };
  setSessionCookie(session);
}

export {
  AuthProvider,
  useAuth,
  useUser,
  useSession,
  getSessionCookie,
  setAppleSession,
};
