import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { Capacitor } from "@capacitor/core";

import useParams from "../utilities/useParams.js";
import { getActiveModalName } from "../views/Modals.js";

const cookies = new Cookies();

const GoogleTagManager = () => {
  const { pathname: page_path } = useLocation();
  const params = useParams();
  const modal_name = getActiveModalName(params);

  useEffect(() => {
    reportEvent({
      event: "page_view",
      page_path: page_path,
    });
  }, [page_path]);

  useEffect(() => {
    if (modal_name) {
      const otherParams = {};

      params.forEach((value, key) => {
        if (key !== modal_name) {
          otherParams[key] = value;
        }
      });

      reportEvent({
        event: "modal_view",
        modal_name,
        page_path,
        ...otherParams,
      });
    }
  }, [page_path, modal_name]); // eslint-disable-line
  return null;
};
export default GoogleTagManager;

export const REACT_APP_MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;

export const reportEvent = (event) => {
  const cookiesAccepted = cookies.get("user-has-accepted-cookies") === "true";

  if (process.env.NODE_ENV === "production") {
    if (cookiesAccepted || Capacitor.isNative) {
      window.dataLayer.push(event);
    }
  } else {
    /*
    console.log(
      event,
      cookiesAccepted
        ? "Would send Google Tag Manager event"
        : "Would NOT send Google Tag Manager Event because cookies are not accepted"
    );
    */
  }
};
